<script setup lang="ts">
import type { SbBlokData } from '@storyblok/vue'
import type { StoryblokStory } from '~/types/storyblok.custom'
import { storyblokCatchError } from '~/utils/storyblokCatchError'
import { storyblokSeoMeta } from '~/composables/storyblok/storyblokSeoMeta'

const { slug } = useRoute().params
const identifier = slug && Array.isArray(slug) && slug.length > 0 ? slug.join('/') : 'home'

let story: Ref<StoryblokStory<SbBlokData>>

try {
  story = await useAsyncStoryblok(identifier, {
    version: useStoryblokVersion(),
  })
} catch (error) {
  throw createError(storyblokCatchError(error))
}

useSeoMeta(storyblokSeoMeta(story))
</script>

<template>
  <StoryblokComponent v-if="story" :story="story" :blok="story.content" />
</template>
